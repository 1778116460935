body {
  margin: 0;
  font-family: 'proxima-nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFEFF1;
}

.MuiMobileStepper-dot {
  width: 15px !important;
  height: 15px !important;
}

h1 {
  font-size: 28px;
}

h4 {
  font-size: 18px;
}

p {
  font-size: 16px;
}
